export const dashboardRoutesPaths = {
    MISSIONS_ROUTE_PATH: '/missions',
    MISSION_ROUTE_PATH: '/guilds/:guildSlug/:slug',
    MISSION_PREVIEW_ROUTE_PATH: '/guilds/:guildSlug/:slug/preview',
    GUILDS_ROUTE_PATH: '/guilds',
    GUILDS_STUDIO_ROUTE_PATH: '/studio',
    GUILD_ROUTE_PATH: '/guilds/:guildSlug',
    LEADERBOARD_ROUTE_PATH: '/leaderboard',
    FRIENDS_ROUTE_PATH: '/friends',
    ENGAGE_ROUTE_PATH: '/engage',
    WALLET_ROUTE_PATH: '/wallet',
    SETTINGS_ROUTE_PATH: '/settings',
    NFT_CERTIFICATE_VERIFICATION_ROUTE_PATH: '/nft-certificate-verification',
    BONUS_ROUTE_PATH: '/bonus',
};
